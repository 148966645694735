import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { deepCopy } from '@/utils/objects'
import { useSearchFiltersStore, defaultFilters } from '@/stores/searchFiltersStore'
import { EVENTS_NAME_LIST } from '@/components/BrandedV2/FormSearchFilters/enums'
import type { FormSearchComposableModule } from '@/composables/BrandedV2/types'
import { DEFAULT_VALUES } from '@/components/SearchFilters/ModalContent/PriceRange/utils'

const useFormSearch = (emit?: FormSearchComposableModule.Emits['submit']): FormSearchComposableModule.UseFormSearchReturn => {
  const searchFiltersStore = useSearchFiltersStore()
  const { locale } = useI18n()
  const { currentFilters: formFilters, selectedFilters, touchedFields } = storeToRefs(searchFiltersStore)

  const defaultPriceRangeValues = computed(() => DEFAULT_VALUES[locale.value as ('co' | 'mx')])

  const filterCount = computed((): number => {
    return Object.values(touchedFields.value).filter(Boolean).length
  })

  const onSubmit = (): void => {
    if (emit) {
      formFilters.value = deepCopy(selectedFilters.value)
      emit(EVENTS_NAME_LIST.FILTERS_CHANGE, deepCopy(formFilters.value))
      resetTouchedFields()
    }
  }

  const resetTouchedFields = (): void => {
    touchedFields.value = searchFiltersStore.generateInitialTouchedFields()
  }

  const resetFilters = (): void => {
    selectedFilters.value = deepCopy(defaultFilters)
    selectedFilters.value.price.min = defaultPriceRangeValues.value.min
    selectedFilters.value.area.min = 1
    resetTouchedFields()
  }

  return {
    formFilters,
    currentFilters: selectedFilters,
    filterCount,
    touchedFields,
    onSubmit,
    resetFilters,
    updateFilters: searchFiltersStore.updateFilters
  }
}

export default useFormSearch
