<script setup lang="ts">
import { computed } from 'vue'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import RoomieButtonLink from '@lahaus-roomie/roomie/src/components/RoomieButtonLink/index.vue'
import FormSearchFilters from '@/components/BrandedV2/FormSearchFilters/index.vue'
import useFormSearch from '@/composables/BrandedV2/use-form-search'
import type { ModalFiltersComponentModule } from '@/components/BrandedV2/FormSearchFiltersModal/types'
import { EVENTS_NAME_LIST } from '@/components/BrandedV2/FormSearchFilters/enums'

defineProps<ModalFiltersComponentModule.Props>()
const emit = defineEmits([EVENTS_NAME_LIST.FILTERS_CHANGE])
const { t } = useI18n()

const { filterCount, onSubmit, resetFilters } = useFormSearch(emit)

const onEnterLabel = computed(() :string => {
  const keyPath = filterCount.value > 0 ? 'applyFilters' : 'label'
  return t(`cta.onEnter.${keyPath}`, filterCount.value)
})

</script>

<script lang="ts">
export default {
  name: 'FormSearchFiltersModal'
}
</script>

<template>
  <div class="form-search-filter-modal">
    <h2 class="form-search-filter-modal__title">
      {{ t('title') }}
    </h2>

    <hr class="border-gray2-600">

    <FormSearchFilters
      :screen-name="screenName"
      class="py-24 pb-80" />

    <div class="w-full left-0 absolute bottom-0 z-10 bg-white py-16 px-24 lg:py-24 border-t border-carbon-light-50">
      <div class="flex flex-row justify-between gap-x-2 flex-wrap">
        <RoomieButtonLink
          :id="`${screenName}-button-see-results`"
          :data-lh-id="`${screenName}-button-see-results`"
          :aria-label="t('cta.onCancel.ariaLabel')"
          variant="primary"
          class="mt-8 self-center"
          @click="resetFilters">
          {{ t('cta.onCancel.label') }}
        </RoomieButtonLink>

        <RoomieButton
          :id="`${screenName}-button-see-results`"
          :data-lh-id="`${screenName}-button-see-results`"
          :aria-label="t('cta.onEnter.ariaLabel')"
          class="mt-8 self-center"
          variant="primary"
          @click="onSubmit">
          {{ onEnterLabel }}
        </RoomieButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-search-filter-modal {
  &__title {
    @apply hl-3-sb lg:hl-2-sb text-pine-600 text-center pt-10 mt-12 mb-10;
  }

  margin-left: -16px;
  margin-right: -16px;
}
</style>

<i18n src="./i18n.json"></i18n>
